@import 'reset.css';

* {
  box-sizing: border-box;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-color: #e5e7ec;
}

@media (max-width: 640px) {
  body {
    background-color: #ffffff;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0;
}

svg g {
  mask: initial;
}
